export default {
  data: () => ({
    loading: false,
    sent: false
  }),
  methods: {
    async sendEmailVerification(user) {
      try {
        this.loading = true;
        await this.$store
          .dispatch('profile/resendRegisterVerificationByEmail', {
            pathParam: 'resendRegisterVerificationByEmail',
            payload: {
              email: user
            }
          })
          .then(res => {
            this.loading = false;
            this.sent = true;
          });
      } catch (err) {
        this.messageData = {
          icon: 'pt-exclamation-circle',
          title: this.$t('forms.errors.throwError'),
          error: true
        };
      } finally {
        this.loading = false;
      }
    }
  }
};
