<template>
  <div class="container">
    <div class="flex flex-col p-6">
      <div class="flex items-center">
        <icon
          :name="message.type === 'success' ? 'pt-check-circle' : 'pt-close-circle'"
          class="text-7xl mr-4"
          :class="message.type === 'success' ? 'text-green' : 'text-red'"
        />
        <div class="flex flex-col items-center">
          <p v-if="!markdown" class="font-bold text-2xl" v-text="message.text" />
          <Markdown v-else :content="message.text" />
          <button
            v-if="sendButton"
            class="bg-blue text-white w-32 h-10 rounded px-4 mt-4"
            type="button"
            @click="sendEmailVerification(user)"
          >
            <span>
              {{ $t(!sent ? 'ui.button.resend' : 'ui.button.sent') }}
            </span>
          </button>
          <p class="text-gray text-base">{{ message.redirectHome ? message.redirectHome : '' }}</p>
          <slot name="additional-button"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VerificationEmail from '~/mixins/VerificationEmail';
import Markdown from '@/components/Markdown';
export default {
  name: 'Confirm',
  components: { Markdown },
  mixins: [VerificationEmail],
  props: {
    message: {
      default: () => ({}),
      type: [Object, String]
    },
    markdown: {
      type: Boolean,
      default: false
    },
    user: {
      default: '',
      type: String
    },
    sendButton: {
      default: false,
      type: Boolean
    }
  }
};
</script>
