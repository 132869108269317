<template>
  <div class="container p-6">
    <div class="flex items-center justify-center font-bold text-2xl">
      <icon name="pt-info-circle" class="text-7xl mr-4 text-green" />
      {{ message }}
    </div>
    <ValidationObserver v-slot="{ handleSubmit }" ref="confirmInscription">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <slot />
        <div v-if="feder === 'feder'" class="flex items-center text-lg mt-3">
          {{ $t('formation.courses.inscription.federDescription') }}
        </div>
        <div v-if="feder === 'feder'" class="pt-6 flex items-center">
          <input
            v-model="checkValue"
            type="checkbox"
            class="no-focus rounded-sm h-5 w-5 text-blue"
          />
          <span class="ml-3"> {{ $t('formation.courses.inscription.feder') }} </span>
        </div>
        <span v-if="errorMessage" class="leading-3 text-red text-xs italic">
          {{ $t('validation.check') }}
        </span>
        <div class="flex justify-center pt-10">
          <button
            class="bg-red text-white h-10 rounded px-4 mr-3"
            type="button"
            @click="closeModal"
          >
            {{ $t('ui.button.cancel') }}
          </button>
          <button class="bg-blue text-white h-10 rounded px-4" type="submit">
            <icon v-if="loading" name="spinner" class="animate-spin mr-2" />
            {{ $t('ui.button.confirm') }}
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: 'Confirm',
  props: {
    nameModal: {
      default: '',
      type: String
    },
    message: {
      default: '',
      type: String
    },
    feder: {
      default: '',
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['confirm'],
  data() {
    return {
      checkValue: false,
      errorMessage: false
    };
  },
  methods: {
    closeModal() {
      this.$modal.close(this.nameModal);
    },
    onSubmit() {
      if (!this.loading) {
        this.feder === 'feder'
          ? !this.checkValue
            ? (this.errorMessage = true)
            : this.$emit('confirm')
          : this.$emit('confirm');
      }
    }
  }
};
</script>
